import {baseChartOption, mergeObjects} from '@/libs/charts/main'

const treeMapChartOption = () => {
    return {
        ...baseChartOption,
        chart: {
            margin:{
                top: 10,
                right: 10,
                bottom: 10,
                left: 10
            },
            type: 'treemap',
            toolbar: {
                show: true,
                offsetY: -20,
                offsetX: -10
            }
        },
        dataLabels: {
            enabled: true
        },
        yaxis: {
            labels: {
                formatter: (item) => {
                    return item / 100
                }
            }
        },
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false,
                dataLabels: {
                    format: 'truncate'
                }
            }
        }
    }
}

function getConfig(rawConfig) {
    if (rawConfig !== null) {
        return mergeObjects(treeMapChartOption(), rawConfig)
    } else {
        return treeMapChartOption()
    }
}

export default {
    getConfig
}
